import React from 'react'
import { Layout, Stack, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Pagination from '@components/Pagination'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import { useBlogCategories } from '@helpers-blog'
import BannerHorizontal from '@widgets/BannerHorizontal'
import BannerVertical from '@widgets/BannerVertical'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'

const Posts = ({
  data: { featuredPosts = {}, recentPosts = {}, paginatedPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const categories = useBlogCategories()

  return (
    <Layout {...props}>
    <Seo title='Find out more about Real Estate investment in Lagos, Abuja Nigeria and Dubai' />
    {/*<GatsbySeo
        title='Find out more about Real Estate investment in Lagos, Abuja Nigeria and Dubai on our Blog.'
        description='Our Blog deep dives into all you need to know about Real Estate investment in Lagos, Abuja and Dubai.'
        openGraph={{
          title: 'Find out more about Real Estate investment in Lagos, Abuja Nigeria and Dubai on our Blog.',
          description: 'Our Blog deep dives into all you need to know about Real Estate investment in Lagos, Abuja and Dubai.',
          url: 'https://www.ownahome.ng/blog',
          images: 
            {
              url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              width: 850,
              height: 650,
              alt: 'find out about investing in real estate'
            },
          site_name: 'Owning a home does not need to make you lose sleep'
        }}
        twitter={{
          handle: '@ownahome_ng',
          site: '@ownahome.ng',
          cardType: 'summary_large_image'
        }}
      />*/}

      <Divider />
      <Divider />
      <Stack effectProps={{ effect: false }}>
        <Categories categories={categories} variant='horizontal' omitTitle />
      </Stack>
      <Divider />
      <Stack effectProps={{ effect: false }}>
        <Main>
          <CardList
            nodes={featuredPosts.nodes}
            limit={3}
            variant='horizontal-cover'
            slider
            fade
            controlPosition='over'
            loading='eager'
            omitCategory
          />
          <Divider space={2} />
          <CardList
            nodes={recentPosts.nodes}
            limit={4}
            columns={[1, 2]}
            variant='horizontal-aside'
            loading='eager'
          />
        </Main>
        <Sidebar sx={{ pl: `3`, flexBasis: `1/4` }}>
          <BannerVertical />
        </Sidebar>
      </Stack>
      <Divider />
      <Stack
        effectProps={{ effect: false }}
        title='Suggested Articles'
        direction={[`column`, `column`, `column`, `row`]}
      ></Stack>

      <Stack effectProps={{ effect: false }}>
        <Main mb={[3, 3, 3, 0]}>
          <CardList
            nodes={featuredPosts.nodes}
            limit={1}
            variant={['vertical', 'horizontal-lg']}
            loading='eager'
          />
        </Main>
        <Sidebar pl={[0, 0, 0, 3]} sx={{ display: [`block`, `block`, `flex`] }}>
          <CardList
            nodes={featuredPosts.nodes}
            limit={8}
            skip={1}
            variant={[
              'horizontal-md',
              'horizontal',
              'horizontal',
              'horizontal-aside'
            ]}
            omitMedia
            omitCategory
            aside
          />
        </Sidebar>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <CardList
            nodes={featuredPosts.nodes}
            limit={3}
            skip={1}
            columns={[1, 1, 1, 3]}
            variant={['horizontal-md', 'horizontal', 'horizontal', 'vertical']}
          />
        </Main>
      </Stack>

      <Divider />
      <Divider />
      <Stack effectProps={{ effect: false }}>
        <BannerHorizontal />
      </Stack>
      <Divider />
      <Stack title='Recently Published'>
        <Main>
          <CardList
            nodes={paginatedPosts.nodes}
            variant={['horizontal', 'vertical']}
            columns={[1, 2, 3, 3]}
            omitMedia
          />
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
        {services.mailchimp && (
          <>
            <Divider />
            <NewsletterExpanded simple />
          </>
        )}
      </PreFooter>
    </Layout>
  )
}

export default Posts
